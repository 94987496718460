import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { useForm, Controller } from "react-hook-form";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import useUpdate from "../../../hook/useUpdate";

export default function MaterialDispatchQuantity({
  material_id,
  issued_quantity,
  url,
}) {
  const [open, setOpen] = useState(true);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      status: "Dispatched",
      material_id: material_id,
      dispatched_quantity: issued_quantity,
    },
  });

  const token = sessionStorage.getItem("token");
  const { loading, update } = useUpdate(url);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data) => {
    update(data, token);
    setOpen(false);
    console.log(data);
  };

  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Loading ...
          </Typography>
        </Box>
      </Backdrop>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{ "& .MuiDialog-paper": { width: "min(400px, 100%)" } }}
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography sx={{ pt: 3 }}>
            Dispatch quantity <span style={{ color: "#e50140" }}>*</span>
          </Typography>
          <Controller
            name="dispatched_quantity"
            control={control}
            rules={{
              required: "Quantity is required",
              min: { value: 1, message: "Quantity must be at least 1" },
              max: {
                value: issued_quantity,
                message: "Quantity cannot exceed " + issued_quantity,
              },
            }}
            render={({ field }) => (
              <>
                <Input
                  type="number"
                  {...field}
                  error={!!errors.dispatched_quantity}
                />
                {errors.dispatched_quantity && (
                  <Typography color="error" variant="caption">
                    {errors.dispatched_quantity.message}
                  </Typography>
                )}
              </>
            )}
          />
        </DialogContent>
        <Divider />
        <DialogActions sx={{ justifyContent: "space-around" }}>
          <Button sx={{ color: "#383315" }} autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button sx={{ color: "#383315" }} onClick={handleSubmit(onSubmit)}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
