import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import { useForm, Controller } from "react-hook-form";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import usePost from "../../hook/usePost";
import Notification from "../notification";

export default function NewExpense() {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const [expense, setExpense] = useState({});

  const token = sessionStorage.getItem("token");

  const url = "https://wow.maflink.com/api/api/expenses";

  const { loading, error, confirmation, post } = usePost(url);

  //   Update total cost when quantity or unit cost changes
  useEffect(() => {
    const total_cost = expense.quantity * expense.unit_cost;
    expense.quantity && expense.unit_cost && setValue("total_cost", total_cost);
  }, [expense.quantity, expense.unit_cost, setValue]);

  // Handle form submission
  const OnSubmit = (expense) => {
    expense.payor = sessionStorage.getItem("user_name");

    post(expense, token);
  };

  // Handle form field changes
  const handleChange = (field) => (e) => {
    const value = e.target.value;
    setExpense({ ...expense, [field]: value });
    setValue(field, value);
  };

  console.log(confirmation);

  return (
    <Box sx={{ maxWidth: 1200 }}>
      <Paper
        elevation={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        <Stack
          sx={{
            width: "100%",
            backgroundColor: "whitesmoke",
            borderBottom: "2px solid #1d3f6b",
            p: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <AddIcon />
          <Typography color="inherit" variant="h6">
            New Expense
          </Typography>
        </Stack>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 3,
            padding: 3,
            alignItems: "center",
          }}
        >
          <Controller
            name="payment_category"
            control={control}
            defaultValue=""
            rules={{ required: "Payment category is required" }}
            render={({ field }) => (
              <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                <InputLabel>
                  Payemnt category <span style={{ color: "#e50140" }}>*</span>
                </InputLabel>
                <Select
                  {...field}
                  onChange={handleChange("payment_category")}
                  error={Boolean(errors.payment_category)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {[
                    "Fuel consumption",
                    "Maintenance",
                    "Transport",
                    "Utility",
                    "Vehicle rent",
                    "Warehouse rent",
                    "Labour direct",
                    "Others",
                  ].map((list) => (
                    <MenuItem key={list} value={list}>
                      {list}
                    </MenuItem>
                  ))}
                </Select>
                {errors.payment_category && (
                  <p
                    style={{
                      color: "#e50140",
                      fontSize: "0.8rem",
                      margin: 0,
                    }}
                  >
                    {errors.payment_category.message}
                  </p>
                )}
              </FormControl>
            )}
          />
          <Controller
            name="payee_name"
            control={control}
            defaultValue=""
            rules={{ required: "Payee name is required" }}
            render={({ field }) => (
              <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                <InputLabel>
                  Payee name <span style={{ color: "#e50140" }}>*</span>
                </InputLabel>
                <Input
                  {...field}
                  onChange={handleChange("payee_name")}
                  error={Boolean(errors.payee_name)}
                />
                {errors.payee_name && (
                  <p
                    style={{ color: "#e50140", fontSize: "0.8rem", margin: 0 }}
                  >
                    {errors.payee_name.message}
                  </p>
                )}
              </FormControl>
            )}
          />
          <Controller
            name="payee_tel"
            control={control}
            defaultValue=""
            rules={{ required: "Payee tel is required" }}
            render={({ field }) => (
              <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                <InputLabel>
                  Payee tel <span style={{ color: "#e50140" }}>*</span>
                </InputLabel>
                <Input
                  {...field}
                  onChange={handleChange("payee_tel")}
                  error={Boolean(errors.payee_tel)}
                />
                {errors.payee_tel && (
                  <p
                    style={{ color: "#e50140", fontSize: "0.8rem", margin: 0 }}
                  >
                    {errors.payee_tel.message}
                  </p>
                )}
              </FormControl>
            )}
          />
          <Controller
            name="payee_email"
            control={control}
            type="email"
            defaultValue=""
            rules={{
              //   required: "Payee email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid email address",
              },
            }}
            render={({ field }) => (
              <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                <InputLabel>
                  Payee email
                  {/* <span style={{ color: "#e50140" }}>*</span> */}
                </InputLabel>
                <Input
                  {...field}
                  onChange={handleChange("payee_email")}
                  error={Boolean(errors.payee_email)}
                />
                {errors.payee_email && (
                  <p
                    style={{ color: "#e50140", fontSize: "0.8rem", margin: 0 }}
                  >
                    {errors.payee_email.message}
                  </p>
                )}
              </FormControl>
            )}
          />{" "}
          <Controller
            name="tin_no"
            control={control}
            defaultValue=""
            rules={{ required: "TIN number is required" }}
            render={({ field }) => (
              <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                <InputLabel>
                  TIN number <span style={{ color: "#e50140" }}>*</span>
                </InputLabel>
                <Input
                  {...field}
                  onChange={handleChange("tin_no")}
                  error={Boolean(errors.tin_no)}
                />
                {errors.tin_no && (
                  <p
                    style={{ color: "#e50140", fontSize: "0.8rem", margin: 0 }}
                  >
                    {errors.tin_no.message}
                  </p>
                )}
              </FormControl>
            )}
          />
          <Controller
            name="payment_reason"
            control={control}
            defaultValue=""
            rules={{ required: "Payment reason is required" }}
            render={({ field }) => (
              <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                <InputLabel>
                  Payment reason <span style={{ color: "#e50140" }}>*</span>
                </InputLabel>
                <Input
                  {...field}
                  onChange={handleChange("payment_reason")}
                  error={Boolean(errors.payment_reason)}
                />
                {errors.payment_reason && (
                  <p
                    style={{ color: "#e50140", fontSize: "0.8rem", margin: 0 }}
                  >
                    {errors.payment_reason.message}
                  </p>
                )}
              </FormControl>
            )}
          />
          <Controller
            name="measuring_unit"
            control={control}
            defaultValue=""
            rules={{ required: "Measuring unit is required" }}
            render={({ field }) => (
              <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                <InputLabel>
                  Measuring unit <span style={{ color: "#e50140" }}>*</span>
                </InputLabel>
                <Select
                  {...field}
                  onChange={handleChange("measuring_unit")}
                  error={Boolean(errors.measuring_unit)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {[
                    "Number",
                    "Meter",
                    "Liter",
                    "Kg",
                    "Day",
                    "Week",
                    "Month",
                    "Year",
                  ].map((measuring_unit) => (
                    <MenuItem key={measuring_unit} value={measuring_unit}>
                      {measuring_unit}
                    </MenuItem>
                  ))}
                </Select>
                {errors.measuring_unit && (
                  <p
                    style={{
                      color: "#e50140",
                      fontSize: "0.8rem",
                      margin: 0,
                    }}
                  >
                    {errors.measuring_unit.message}
                  </p>
                )}
              </FormControl>
            )}
          />
          <Controller
            name="quantity"
            control={control}
            defaultValue=""
            rules={{ required: "Quantity is required" }}
            render={({ field }) => (
              <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                <InputLabel>
                  Quantity <span style={{ color: "#e50140" }}>*</span>
                </InputLabel>
                <Input
                  {...field}
                  type="number"
                  onChange={handleChange("quantity")}
                  error={Boolean(errors.quantity)}
                />
                {errors.quantity && (
                  <p
                    style={{ color: "#e50140", fontSize: "0.8rem", margin: 0 }}
                  >
                    {errors.quantity.message}
                  </p>
                )}
              </FormControl>
            )}
          />
          <Controller
            name="unit_cost"
            control={control}
            defaultValue=""
            rules={{ required: "Unit cost is required" }}
            render={({ field }) => (
              <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                <InputLabel>
                  Unit cost <span style={{ color: "#e50140" }}>*</span>
                </InputLabel>
                <Input
                  {...field}
                  type="number"
                  onChange={handleChange("unit_cost")}
                  error={Boolean(errors.unit_cost)}
                />
                {errors.unit_cost && (
                  <p
                    style={{ color: "#e50140", fontSize: "0.8rem", margin: 0 }}
                  >
                    {errors.unit_cost.message}
                  </p>
                )}
              </FormControl>
            )}
          />
          <Controller
            name="total_cost"
            control={control}
            defaultValue=""
            rules={{ required: "Total cost is required" }}
            render={({ field }) => (
              <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                <InputLabel>
                  Total cost <span style={{ color: "#e50140" }}>*</span>
                </InputLabel>
                <Input
                  {...field}
                  type="number"
                  onChange={handleChange("total_cost")}
                  error={Boolean(errors.total_cost)}
                />
                {errors.total_cost && (
                  <p
                    style={{ color: "#e50140", fontSize: "0.8rem", margin: 0 }}
                  >
                    {errors.total_cost.message}
                  </p>
                )}
              </FormControl>
            )}
          />
          <Controller
            name="vat"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                <InputLabel>VAT</InputLabel>
                <Input {...field} onChange={handleChange("vat")} />
              </FormControl>
            )}
          />
          <Controller
            name="with_tax"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                <InputLabel>With tax</InputLabel>
                <Input {...field} onChange={handleChange("with_tax")} />
              </FormControl>
            )}
          />
          <Controller
            name="receipt_no"
            control={control}
            defaultValue=""
            // rules={{ required: "Receipt no is required" }}
            render={({ field }) => (
              <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                <InputLabel>
                  Receipt no
                  {/* <span style={{ color: "#e50140" }}>*</span> */}
                </InputLabel>
                <Input
                  {...field}
                  onChange={handleChange("receipt_no")}
                  error={Boolean(errors.receipt_no)}
                />
                {errors.receipt_no && (
                  <p
                    style={{ color: "#e50140", fontSize: "0.8rem", margin: 0 }}
                  >
                    {errors.receipt_no.message}
                  </p>
                )}
              </FormControl>
            )}
          />
          <Controller
            name="comments"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                <InputLabel>Comments</InputLabel>
                <Input {...field} onChange={handleChange("comments")} />
              </FormControl>
            )}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSubmit(OnSubmit)}
          sx={{
            mt: 2,
            backgroundColor: " #1d3f6b",
            "&:hover": { backgroundColor: "#124c97" },
          }}
        >
          Submit
        </Button>
      </Paper>
      {confirmation && <Notification message={confirmation.message} />}
      {error && <Notification message={error} color="#e50140" />}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#1d3f6b",
            borderRadius: 2,
            padding: 2,
          }}
        >
          <CircularProgress sx={{ color: "white" }} />
          <Typography variant="h6" sx={{ mt: 2, color: "white" }}>
            Submitting ...
          </Typography>
        </Box>
      </Backdrop>
    </Box>
  );
}
