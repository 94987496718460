import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ListItemText from "@mui/material/ListItemText";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import Button from "@mui/material/Button";
import { Badge, ListItemButton, ListItemIcon } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import SegmentOutlinedIcon from "@mui/icons-material/SegmentOutlined";
import { Link } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MinimizeOutlinedIcon from "@mui/icons-material/MinimizeOutlined";
import AddIcon from "@mui/icons-material/Add";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import LogoutConfirmation from "../logoutConfirmation";
import AddUser from "../userManagement/addUser";
import Users from "../userManagement/user";
import AddMaterial from "../inventory/addMaterial";
import NewOrder from "../inventory/newOrder";
import Reorders from "../inventory/reorder";
import IssueMaterial from "../production/issueMaterial";
import AddProduct from "../production/addProduct";
import MaterialDispatched from "../inventory/dispatchHistory/materialDispatched";
import ProductDispatched from "../inventory/dispatchHistory/productDispatched";
import MaterialIssuance from "../production/materialIssuance";
import AllProducts from "../production/allProducts";
import Material from "../inventory/material";
import Product from "../inventory/product";
import NewDispatch from "../inventory/newDispatch";
import Sales from "../sales/sales";
import NewSale from "../sales/newSale";
import Preorder from "../sales/preorder";
import NewPreOrder from "../sales/newPreorder";
import NewExpense from "../finance/newExpenses";
import FuelConsumption from "../finance/fuel";
import Maintenance from "../finance/maintenance";
import Transport from "../finance/transport";
import VehicleRent from "../finance/vehicleRent";
import WarehouseRent from "../finance/warehouseRent";
import Utility from "../finance/utility";
import Labour from "../finance/labour";
import Other from "../finance/others";
import SummarisedReport from "../summarisedReport";
import SmallDashboard from "../dashboard/smallDashboard";
import Inventory2Outlined from "@mui/icons-material/Inventory2Outlined";

const drawerWidth = 270;

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(0),
  [theme.breakpoints.up("xs")]: {
    padding: theme.spacing(0),
    display: open && "none",
    flexDirection: "column",
  },
  padding: theme.spacing(1),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(1),
    display: open && "flex",
    flexDirection: "column",
  },
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(2),
    display: open && "flex",
    flexDirection: "column",
  },
  [theme.breakpoints.up("lg")]: {
    padding: theme.spacing(3),
    display: open && "flex",
    flexDirection: "column",
  },
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(100% - ${drawerWidth}px)`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
  backgroundColor: " white",
  position: "fixed",
  top: 0,
  zIndex: 1,
}));

function Logout() {
  const [logout, setLogout] = React.useState(false);

  const handleLogout = () => {
    setLogout(!logout);
  };
  return (
    <Button
      onClick={handleLogout}
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        textTransform: "none",
        color: "#3f3f3fd6",
      }}
    >
      {logout && <LogoutConfirmation />}
      <LogoutIcon fontSize="small" sx={{ color: "#1d3f6b" }} />
      <Typography sx={{ color: "#1d3f6b" }}>Logout</Typography>
    </Button>
  );
}

function Alert() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          textTransform: "none",
          color: "#3f3f3fd6",
        }}
      >
        <Badge
          badgeContent={3}
          sx={{
            "& .MuiBadge-badge": {
              backgroundColor: "#e50140",
              color: "white",
            },
          }}
        >
          <NotificationsNoneIcon sx={{ color: "#1d3f6b" }} />
        </Badge>

        <Typography sx={{ color: "#1d3f6b" }}>Alert</Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            width: "min(800px, 100%)",
            marginRight: "20px",
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            backgroundColor: "#1d3f6b",
            borderBottom: "2px solid #1d3f6b",
            mt: -1,
            p: 2,
            color: "#fff",
          }}
        >
          Important alerts
        </Box>
        <MenuItem onClick={handleClose}>No alerts yet</MenuItem>
      </Menu>
    </div>
  );
}

function DateFilter({ handleDateFilter }) {
  const [fromValue, setFromValue] = React.useState(null);
  const [toValue, setToValue] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleDateFilter(fromValue, toValue);
  }, [fromValue, toValue]);

  return (
    <div>
      <Button
        variant="text"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          textTransform: "none",
        }}
      >
        <FilterListOutlinedIcon fontSize="small" sx={{ color: "#1d3f6b" }} />
        <Typography sx={{ whiteSpace: "nowrap", color: "#1d3f6b" }}>
          Date selector
        </Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box sx={{ m: 4 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker", "DatePicker"]}>
              <DatePicker
                label="From"
                value={fromValue}
                onChange={(newValue) => setFromValue(newValue)}
              />
              <DatePicker
                label="To"
                value={toValue}
                onChange={(newValue) => setToValue(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Box>
      </Menu>
    </div>
  );
}

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid white`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

//Nav bar dropdown view component
function NavbarDropdown({ handleDateFilter, dateSelector }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleDropDown = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: { lg: "none", md: "flex", sm: "flex", xs: "flex" },
      }}
    >
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleDropDown}
      >
        <SegmentOutlinedIcon
          sx={{
            fontSize: "2.25em",
            color: "#1d3f6b",
          }}
        />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "basic-button" }}
        sx={{
          ml: -3,
        }}
      >
        {dateSelector && (
          <MenuItem>
            <DateFilter handleDateFilter={handleDateFilter} />
          </MenuItem>
        )}
        <MenuItem>
          <Alert />
        </MenuItem>
        <MenuItem>
          <Logout />
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default function ProductionOperator() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(
    window.innerWidth > 1100 ? true : false
  );
  const [dateSelector, setDateSelector] = React.useState(false);

  const [fromValue, setFromValue] = React.useState(null);
  const [toValue, setToValue] = React.useState(null);

  // Initialize dates as null (no default values)
  const [date_start, setDateStart] = useState(null);
  const [date_end, setDateEnd] = useState(null);

  // Function to handle date range filter
  const handleDateFilter = (startDate, endDate) => {
    // Format the dates if they are provided, otherwise keep them as null
    setDateStart(startDate ? dayjs(startDate).format("YYYY-MM-DD") : null);
    setDateEnd(endDate ? dayjs(endDate).format("YYYY-MM-DD") : null);
  };

  function handleDateSelector() {
    setDateSelector(true);
  }

  useEffect(() => {
    handleDateFilter(fromValue, toValue);
  }, [fromValue, toValue]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        height: "100%",
      }}
    >
      <CssBaseline />
      <Drawer
        sx={{
          width: open && drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "white",
            color: "black",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Box
            sx={{
              width: 250,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
              backgroundColor: " white",
              pt: 3,
              pl: 1,
            }}
          >
            <Link href="/">
              <img
                style={{
                  width: 200,
                  height: "auto",
                }}
                src="https://wow.maflink.com/logo.png"
              />
            </Link>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" && (
                <ArrowBackRoundedIcon
                  sx={{
                    color: "black",
                  }}
                />
              )}
            </IconButton>
          </Box>
        </DrawerHeader>
        <List sx={{ ml: 2, mt: 15 }}>
          <ListItemButton href="/">
            <ListItemIcon sx={{ color: "black" }}>
              <DashboardOutlinedIcon />
            </ListItemIcon>
            <ListItemText>Dashboard</ListItemText>
          </ListItemButton>
          <Divider sx={{ width: "90%", backgroundColor: "#878798" }} />
          <ListItemButton href="/production/issue-material">
            <ListItemIcon sx={{ color: "black" }}>
              <AddBoxOutlinedIcon />
            </ListItemIcon>
            <ListItemText>Issue material</ListItemText>
          </ListItemButton>
          <ListItemButton href="/production/add-product">
            <ListItemIcon sx={{ color: "black" }}>
              <AddIcon />
            </ListItemIcon>
            <ListItemText>Add Product</ListItemText>
          </ListItemButton>
          <Divider sx={{ width: "90%", backgroundColor: "#878798" }} />
          <ListItemButton href="/production/product">
            <ListItemIcon sx={{ color: "black" }}>
              <ShoppingCartOutlinedIcon />
            </ListItemIcon>
            <ListItemText>All Products</ListItemText>
          </ListItemButton>{" "}
          <ListItemButton href="/production/material">
            <ListItemIcon sx={{ color: "black" }}>
              <Inventory2Outlined />
            </ListItemIcon>
            <ListItemText>All Material</ListItemText>
          </ListItemButton>
        </List>
      </Drawer>
      <Main open={open}>
        <Box
          sx={{
            width: "100%",
            position: "sticky",
            top: 0,
            zIndex: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "white",
            cursor: "pointer",
            pt: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            {!open && (
              <>
                <MenuIcon
                  sx={{
                    fontSize: "2.25em",
                    color: "black",
                    ml: 2,
                  }}
                  onClick={handleDrawerOpen}
                />
                <Link href="/" underline="none">
                  <img
                    style={{
                      width: 120,
                      height: "auto",
                    }}
                    src="https://wow.maflink.com/logo.png"
                  />
                </Link>
              </>
            )}
            <Typography
              variant="h6"
              sx={{
                display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
                p: 2,
              }}
            >
              Production Management
            </Typography>
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "none", lg: "flex" },
              flexFlow: "row nowrap",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 3,
            }}
          >
            {dateSelector && <DateFilter handleDateFilter={handleDateFilter} />}
            <Logout />
            <Divider orientation="vertical" flexItem />
            <Alert />
          </Box>
          <NavbarDropdown
            handleDateFilter={handleDateFilter}
            dateSelector={dateSelector}
          />
        </Box>
        <Divider />
        <Box
          sx={{
            mt: 3,
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <SmallDashboard
                    date_start={date_start}
                    date_end={date_end}
                    handleDateSelector={handleDateSelector}
                  />
                }
              />
              <Route path="/inventory/add-material" element={<AddMaterial />} />
              <Route path="/inventory/new-order" element={<NewOrder />} />
              <Route path="/inventory/new-dispatch" element={<NewDispatch />} />
              <Route path="/inventory/material" element={<Material />} />
              <Route
                path="/inventory/dispatch/material"
                element={<MaterialDispatched />}
              />
              <Route path="/inventory/product" element={<Product />} />
              <Route
                path="/inventory/dispatch/product"
                element={<ProductDispatched />}
              />
              <Route path="/inventory/reorders" element={<Reorders />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </BrowserRouter>
        </Box>
      </Main>
    </Box>
  );
}
