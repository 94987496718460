import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import AddIcon from "@mui/icons-material/Add";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useForm, Controller } from "react-hook-form";
import usePost from "../../hook/usePost";
import Notification from "../notification";
import useFetch from "../../hook/useFetch";

export default function IssueMaterial() {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const [material, setMaterial] = useState({});
  const [rows, setRows] = useState([]);

  const token = sessionStorage.getItem("token");

  const fetchUrl = `https://wow.maflink.com/api/api/materials?page=${1}&search=${encodeURIComponent(
    ""
  )}&date_start=${encodeURIComponent(null)}&date_end=${encodeURIComponent(
    null
  )}&location=${encodeURIComponent(null)}`;

  const url = "https://wow.maflink.com/api/api/material-issuances";

  const [data] = useFetch(fetchUrl);
  const { loading, error, confirmation, post } = usePost(url);

  useEffect(() => {
    data && setRows(data);
  }, [data]);

  const MaterialName = rows.filter(
    (row) => row.material_name === material.material_name
  );

  console.log(MaterialName[0]);

  useEffect(() => {
    if (material) {
      setMaterial({
        ...material,
        material_id: MaterialName[0] ? MaterialName[0].material_id : "",
        category: MaterialName[0] ? MaterialName[0].category : "",
        measuring_unit: MaterialName[0] ? MaterialName[0].measuring_unit : "",
        unit_cost: MaterialName[0] ? MaterialName[0].unit_cost : "",
        total_cost:
          MaterialName[0] && material[0]
            ? MaterialName[0].total_cost * (material[0].issued_quantity || 0)
            : "",
      });

      setValue(
        "material_id",
        MaterialName[0] ? MaterialName[0].material_id : ""
      );
      setValue("category", MaterialName[0] ? MaterialName[0].category : "");
      setValue(
        "measuring_unit",
        MaterialName[0] ? MaterialName[0].measuring_unit : ""
      );
      setValue("unit_cost", MaterialName[0] ? MaterialName[0].unit_cost : "");
      setValue(
        "total_cost",
        MaterialName[0]
          ? MaterialName[0].unit_cost * (material.issued_quantity || 0)
          : ""
      );
    }
  }, [
    material.material_id,
    material.category,
    material.measuring_unit,
    material.unit_cost,
    material.total_cost,
    rows,
    setValue,
  ]);

  const OnSubmit = (material) => {
    material.data_encoder = sessionStorage.getItem("full_name");

    post(material, token);
  };

  const handleChange = (field) => (e) => {
    const value = e.target.value;
    setMaterial({ ...material, [field]: value });
    setValue(field, value);
  };

  return (
    <Box sx={{ maxWidth: 1200 }}>
      <Paper
        elevation={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        <Stack
          sx={{
            width: "100%",
            backgroundColor: "whitesmoke",
            borderBottom: "2px solid #1d3f6b",
            p: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <AddIcon />
          <Typography color="inherit" variant="h6">
            Issue material
          </Typography>
        </Stack>
        <FormGroup
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 5,
            padding: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 3,
              "& .MuiTextField-root": { m: 1, width: "21ch" },
            }}
          >
            <Controller
              name="material_name"
              control={control}
              defaultValue=""
              rules={{ required: "Material name is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Material name <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    {...field}
                    onChange={handleChange("material_name")}
                    error={Boolean(errors.material_name)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {rows.map((row) => (
                      <MenuItem
                        key={row.material_name}
                        value={row.material_name}
                      >
                        {row.quantity > 0 && row.material_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.material_name && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.material_name.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="material_id"
              control={control}
              defaultValue=""
              rules={{ required: "Material id is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Material id <span style={{ color: "#e50140" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("material_id")}
                    error={Boolean(errors.material_id)}
                  />
                  {errors.material_id && (
                    <p
                      style={{
                        color: "#e50140",
                        fontSize: "0.8rem",
                        margin: 0,
                      }}
                    >
                      {errors.material_id.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="category"
              control={control}
              defaultValue=""
              rules={{ required: "Category id is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Category <span style={{ color: "#e50140" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("category")}
                    error={Boolean(errors.category)}
                  />
                  {errors.category && (
                    <p
                      style={{
                        color: "#e50140",
                        fontSize: "0.8rem",
                        margin: 0,
                      }}
                    >
                      {errors.category.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            {/* <Controller
              name="category"
              control={control}
              defaultValue=""
              rules={{ required: "Category is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Category <span style={{ color: "#e50140" }}>*</span>
                  </InputLabel>
                  <Select
                    {...field}
                    onChange={handleChange("category")}
                    error={Boolean(errors.category)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {["Packaging", "Chemical", "Others"].map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.category && (
                    <p
                      style={{
                        color: "#e50140",
                        fontSize: "0.8rem",
                        margin: 0,
                      }}
                    >
                      {errors.category.message}
                    </p>
                  )}
                </FormControl>
              )}
            /> */}
            <Controller
              name="measuring_unit"
              control={control}
              defaultValue=""
              rules={{ required: "Measuring unit is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Measuring unit <span style={{ color: "#e50140" }}>*</span>
                  </InputLabel>
                  <Select
                    {...field}
                    onChange={handleChange("measuring_unit")}
                    error={Boolean(errors.measuring_unit)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {["Number", "Pieces", "Liter", "Kg"].map(
                      (measuring_unit) => (
                        <MenuItem key={measuring_unit} value={measuring_unit}>
                          {measuring_unit}
                        </MenuItem>
                      )
                    )}
                  </Select>
                  {errors.measuring_unit && (
                    <p
                      style={{
                        color: "#e50140",
                        fontSize: "0.8rem",
                        margin: 0,
                      }}
                    >
                      {errors.measuring_unit.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="issued_quantity"
              control={control}
              defaultValue=""
              rules={{
                required: "Quantity is required",
                min: {
                  value: 1,
                  message: `Minimum quantity to issue is 1`,
                },

                max: {
                  value: MaterialName[0] ? MaterialName[0].quantity : Infinity,
                  message:
                    MaterialName[0] && MaterialName[0].quantity > 0
                      ? `Maximum available quantity is ${MaterialName[0].quantity}`
                      : `No  enough material`,
                },
              }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Quantity <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    type="number"
                    onChange={handleChange("issued_quantity")}
                    error={Boolean(errors.issued_quantity)}
                  />
                  {errors.issued_quantity && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.issued_quantity.message}
                    </p>
                  )}
                </FormControl>
              )}
            />{" "}
            <Controller
              name="unit_cost"
              control={control}
              defaultValue=""
              rules={{ required: "Unit cost is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Unit cost <span style={{ color: "#e50140" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    type="number"
                    onChange={handleChange("unit_cost")}
                    error={Boolean(errors.unit_cost)}
                  />
                  {errors.unit_cost && (
                    <p
                      style={{
                        color: "#e50140",
                        fontSize: "0.8rem",
                        margin: 0,
                      }}
                    >
                      {errors.unit_cost.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="total_cost"
              control={control}
              defaultValue=""
              rules={{ required: "Total cost is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Total cost <span style={{ color: "#e50140" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    type="number"
                    error={Boolean(errors.total_cost)}
                  />
                  {errors.total_cost && (
                    <p
                      style={{
                        color: "#e50140",
                        fontSize: "0.8rem",
                        margin: 0,
                      }}
                    >
                      {errors.total_cost.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="notes"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>Notes</InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("notes")}
                    error={Boolean(errors.notes)}
                  />
                  {errors.notes && (
                    <p
                      style={{
                        color: "#e50140",
                        fontSize: "0.8rem",
                        margin: 0,
                      }}
                    >
                      {errors.notes.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit(OnSubmit)}
            sx={{
              mt: 2,
              backgroundColor: " #1d3f6b",
              "&:hover": { backgroundColor: "#124c97" },
            }}
          >
            Submit
          </Button>
          {confirmation && <Notification message={confirmation.message} />}
          {error && <Notification message={error} color="#e50140" />}
        </FormGroup>
      </Paper>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#1d3f6b",
            borderRadius: 2,
            padding: 2,
          }}
        >
          <CircularProgress sx={{ color: "white" }} />
          <Typography variant="h6" sx={{ mt: 2, color: "white" }}>
            Submitting ...
          </Typography>
        </Box>
      </Backdrop>
    </Box>
  );
}
