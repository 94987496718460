import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import AppsIcon from "@mui/icons-material/Apps";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import dayjs from "dayjs";
import { format, toZonedTime } from "date-fns-tz";
import { Button, Stack } from "@mui/material";
import useUpdate from "../../hook/useUpdate";
import useFetch from "../../hook/useFetch";
import Receipt from "../receipt";
import LoadingSkeleton from "../loadingSkeleton";

const EditableCell = ({ row, field, field2, field3, align, onBlur }) => (
  <TableCell
    onBlur={(e) => onBlur(e.target.textContent)}
    align={align}
    sx={{ whiteSpace: "nowrap" }}
  >
    {/* Render the main field */}
    {row[field]}

    {/* Render additional fields conditionally */}
    {field2 && <div>{field2}</div>}
    {field3 && <div>{field3}</div>}
  </TableCell>
);

function EnhancedTableHead({ onSelectAllClick, numSelected, rowCount }) {
  return (
    <TableHead
      sx={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "white" }}
    >
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {[
          "No",
          "Product Name",
          "Product ID",
          "Quantity",
          "Unit Price",
          "Total Price",
          "Tax",
          "Withholding tax",
          "Payment Method",
          "Customer Name",
          "Customer Contact",
          "Date of Sale",
          "Last Updated",
          "Invoice Number",
          "Receipt",
          "Sales person",
        ].map((headCell) => (
          <TableCell
            key={headCell}
            sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}
          >
            {headCell}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar() {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexFlow: "row nowrap",
        alignItems: "center",
        // backgroundColor: "#1d3f6b",
        borderBottom: "2px solid #1d3f6b",
        borderRadius: "5px 5px 0 0",
        p: 1.5,
        gap: 2,
      }}
    >
      <AppsIcon sx={{ color: "#1d3f6b" }} />
      <Typography variant="h6" sx={{ color: "#1d3f6b" }}>
        Recent Sales
      </Typography>
    </Box>
  );
}

export default function RecentSales({ dateStart, dateEnd }) {
  const tableContainerHeight = window.innerHeight;
  const [selected, setSelected] = useState([]);
  const [rows, setRows] = useState([]);
  const [product, setProduct] = useState({});
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [receipt, setReceipt] = useState(false);
  const [comp, setComp] = useState("");

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const fetchUrl = `https://wow.maflink.com/api/api/sales-transactions?page=${page}&search=${encodeURIComponent(
    search
  )}&date_start=${encodeURIComponent(dateStart)}&date_end=${encodeURIComponent(
    dateEnd
  )}`;

  const [data, fetchData] = useFetch(fetchUrl);

  useEffect(() => {
    if (data) setRows(data.data);
  }, [data]);

  function handleReceipt(data) {
    setReceipt(!receipt);
    setComp(data);
  }

  // console.log(rows);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.no);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, no) => {
    const selectedIndex = selected.indexOf(no);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, no);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (no) => selected.indexOf(no) !== -1;

  return (
    <Paper
      sx={{
        width: { lg: "70%", md: "70%", sm: "100%", xs: "100%" },
      }}
    >
      {receipt && <Receipt data={comp} />}
      <EnhancedTableToolbar numSelected={selected.length} />
      {data ? (
        rows.length > 0 ? (
          <TableContainer
            sx={{
              height: `calc(${tableContainerHeight}px - 240px)`,
              overflowY: "scroll",
            }}
          >
            <Table
              sx={{ minWidth: "100%", overflowY: "scroll" }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={rows.length}
              />{" "}
              <TableBody>
                {rows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  return (
                    <TableRow
                      hover={row.availability === "available"}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{
                        backgroundColor:
                          row.availability !== "available" && "#ffebc1",
                      }}
                    >
                      <TableCell
                        padding="checkbox"
                        onClick={(event) => handleClick(event, row.id)}
                      >
                        <Checkbox color="primary" checked={isItemSelected} />
                      </TableCell>
                      <TableCell>{row.id}</TableCell>
                      <EditableCell
                        row={row}
                        field="product_name"
                        align="left"
                        onBlur={(content) =>
                          setProduct({ ...product, product_name: content })
                        }
                      />
                      <EditableCell
                        row={row}
                        field="product_id"
                        align="left"
                        onBlur={(content) =>
                          setProduct({ ...product, product_id: content })
                        }
                      />
                      <EditableCell
                        row={row}
                        field="quantity"
                        align="left"
                        onBlur={(content) =>
                          setProduct({ ...product, quantity: content })
                        }
                      />
                      <EditableCell
                        row={row}
                        field="unit_price"
                        align="left"
                        onBlur={(content) =>
                          setProduct({ ...product, unit_price: content })
                        }
                      />
                      <EditableCell
                        row={row}
                        field="total_price"
                        align="left"
                        onBlur={(content) =>
                          setProduct({ ...product, total_price: content })
                        }
                      />
                      <EditableCell
                        row={row}
                        field="tax"
                        align="left"
                        onBlur={(content) =>
                          setProduct({ ...product, tax: content })
                        }
                      />
                      <EditableCell
                        row={row}
                        field="withholding_tax"
                        align="left"
                        onBlur={(content) =>
                          setProduct({ ...product, withholding_tax: content })
                        }
                      />
                      <EditableCell
                        row={row}
                        field="payment_method"
                        field2={
                          row.payment_method === "Transfer" ? (
                            <>
                              <b>{row.bank_name}:</b> {row.txn}
                            </>
                          ) : null
                        }
                        field3={
                          row.payment_method === "Check" ||
                          row.payment_method === "Debit" ? (
                            <>
                              <b>Due date:</b> {row.due_date}
                            </>
                          ) : null
                        }
                        align="left"
                        onBlur={(content) =>
                          setProduct({ ...product, payment_method: content })
                        }
                      />
                      <EditableCell
                        row={row}
                        field="customer_name"
                        align="left"
                        onBlur={(content) =>
                          setProduct({ ...product, customer_name: content })
                        }
                      />
                      <EditableCell
                        row={row}
                        field="customer_contact"
                        align="left"
                        onBlur={(content) =>
                          setProduct({ ...product, customer_contact: content })
                        }
                      />
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {row.created_at
                          ? format(
                              toZonedTime(
                                new Date(row.created_at),
                                userTimezone
                              ),
                              "yyyy-MM-dd HH:mm"
                            )
                          : ""}
                      </TableCell>{" "}
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {row.updated_at
                          ? format(
                              toZonedTime(
                                new Date(row.updated_at),
                                userTimezone
                              ),
                              "yyyy-MM-dd HH:mm"
                            )
                          : ""}
                      </TableCell>{" "}
                      <EditableCell
                        row={row}
                        field="invoice_number"
                        align="left"
                        onBlur={(content) =>
                          setProduct({ ...product, invoice_number: content })
                        }
                      />
                      <TableCell>
                        <Button
                          variant="contained"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexFlow: "row nowrap",
                            cursor: "pointer",
                            textTransform: "none",
                            gap: 1,
                            backgroundColor: "#1d3f6b",
                          }}
                          onClick={() => handleReceipt(row)}
                        >
                          <PreviewOutlinedIcon />
                          <Typography>Preview</Typography>
                        </Button>
                      </TableCell>
                      <EditableCell
                        row={row}
                        field="data_encoder"
                        align="left"
                      />
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography sx={{ p: 5, color: "#888" }}>Not found</Typography>
        )
      ) : (
        <Box sx={{ p: 3, maxWidth: 1200 }}>
          <LoadingSkeleton />
        </Box>
      )}
    </Paper>
  );
}
