import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import AddIcon from "@mui/icons-material/Add";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useForm, Controller } from "react-hook-form";
import usePost from "../../hook/usePost";
import Notification from "../notification";
import useFetch from "../../hook/useFetch";

export default function NewDispatch() {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const [product, setProduct] = useState({});
  const [users, setUsers] = useState([]);
  const [prices, setPrice] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [date_start, setDateStart] = useState(null);
  const [date_end, setDateEnd] = useState(null);

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const token = sessionStorage.getItem("token");
  const url = "https://wow.maflink.com/api/api/sales-dispatchs";

  const fetchUrl = `https://wow.maflink.com/api/api/users?role=${encodeURIComponent(
    "Sales Associate"
  )}`;

  const productUrl = `https://wow.maflink.com/api/api/products?page=${page}&search=${encodeURIComponent(
    search
  )}&date_start=${encodeURIComponent(date_start)}&date_end=${encodeURIComponent(
    date_end
  )}`;

  const [data] = useFetch(fetchUrl);

  const [prData] = useFetch(productUrl);

  useEffect(() => {
    data && setUsers(data);
    prData && setPrice(prData);
  }, [data, prData]);

  const priceId = prices.filter(
    (price) => price.product_name === product.product_name
  );

  const { loading, error, confirmation, post } = usePost(url);

  // Update product_id, unit_price, total_price when product_name changes
  useEffect(() => {
    if (product) {
      setProduct({
        ...product,
        product_id: priceId[0] ? priceId[0].product_id : "",
        unit_price: priceId[0] ? priceId[0].unit_price : "",
        total_price: product.dispatched_quantity
          ? product.dispatched_quantity * product.unit_price
          : "",
      });

      setValue("product_id", priceId[0] ? priceId[0].product_id : "");

      setValue("unit_price", priceId[0] ? priceId[0].unit_price : "");

      setValue("total_price", priceId[0] ? priceId[0].total_price : "");

      priceId[0] &&
        product.dispatched_quantity &&
        setValue(
          "total_price",
          product.dispatched_quantity
            ? product.dispatched_quantity * priceId[0].unit_price
            : ""
        );
    }
  }, [
    product.product_name,
    product.dispatched_quantity,
    product.unit_price,
    product.total_price,
    prices,
    setValue,
  ]);

  const OnSubmit = (product) => {
    product.data_encoder = sessionStorage.getItem("full_name");

    product.status = "Dispatched";

    post(product, token);
  };

  const handleChange = (field) => (e) => {
    const value = e.target.value;
    setProduct({ ...product, [field]: value });
    setValue(field, value);
  };

  // Sort rows only when they are updated
  const sortedRows = prices.sort((a, b) => {
    // Function to convert "1/2" to 0.5, "1" to 1, etc.
    const getNumericalValue = (productName) => {
      const numberPart = productName.split(" ")[0]; // Extract the first part (before the space)
      if (numberPart.includes("/")) {
        const [numerator, denominator] = numberPart.split("/").map(Number);
        return numerator / denominator;
      }
      return parseFloat(numberPart);
    };

    // Compare based on the numerical values
    return (
      getNumericalValue(a.product_name) - getNumericalValue(b.product_name)
    );
  });

  return (
    <Box sx={{ maxWidth: 1200 }}>
      <Paper
        elevation={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        <Stack
          sx={{
            width: "100%",
            backgroundColor: "whitesmoke",
            borderBottom: "2px solid #1d3f6b",
            p: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <AddIcon />
          <Typography color="inherit" variant="h6">
            New Dispach
          </Typography>
        </Stack>
        <FormGroup
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 5,
            padding: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 3,
              "& .MuiTextField-root": { m: 1, width: "21ch" },
            }}
          >
            <Controller
              name="sales_person"
              control={control}
              defaultValue=""
              rules={{ required: "Sales person is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Sales person <span style={{ color: "#e50140" }}>*</span>
                  </InputLabel>
                  <Select
                    {...field}
                    onChange={handleChange("sales_person")}
                    error={Boolean(errors.sales_person)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {users.map((user) => (
                      <MenuItem key={user.user_name} value={user.user_name}>
                        {user.full_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.sales && (
                    <p
                      style={{
                        color: "#e50140",
                        fontSize: "0.8rem",
                        margin: 0,
                      }}
                    >
                      {errors.sales.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="sales_zone"
              control={control}
              defaultValue=""
              rules={{ required: "Sales zone is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Sales zone <span style={{ color: "#e50140" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("sales_zone")}
                    error={Boolean(errors.sales_zone)}
                  />
                  {errors.sales_zone && (
                    <p
                      style={{
                        color: "#e50140",
                        fontSize: "0.8rem",
                        margin: 0,
                      }}
                    >
                      {errors.sales_zone.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="product_name"
              control={control}
              defaultValue=""
              rules={{ required: "Product name is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Product name <span style={{ color: "#e50140" }}>*</span>
                  </InputLabel>
                  <Select
                    {...field}
                    onChange={handleChange("product_name")}
                    error={Boolean(errors.product_name)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {sortedRows.map((row) => (
                      <MenuItem key={row.product_name} value={row.product_name}>
                        {row.received_quantity > 0 && row.product_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.product && (
                    <p
                      style={{
                        color: "#e50140",
                        fontSize: "0.8rem",
                        margin: 0,
                      }}
                    >
                      {errors.product.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="product_id"
              control={control}
              defaultValue=""
              rules={{ required: "Product id is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Product id <span style={{ color: "#e50140" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("product_id")}
                    error={Boolean(errors.product_id)}
                  />
                  {errors.product_id && (
                    <p
                      style={{
                        color: "#e50140",
                        fontSize: "0.8rem",
                        margin: 0,
                      }}
                    >
                      {errors.product_id.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="measuring_unit"
              control={control}
              defaultValue=""
              rules={{ required: "Measuring unit is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Measuring unit <span style={{ color: "#e50140" }}>*</span>
                  </InputLabel>
                  <Select
                    {...field}
                    onChange={handleChange("measuring_unit")}
                    error={Boolean(errors.measuring_unit)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {["Number", "Pieces", "Liter", "Kg"].map(
                      (measuring_unit) => (
                        <MenuItem key={measuring_unit} value={measuring_unit}>
                          {measuring_unit}
                        </MenuItem>
                      )
                    )}
                  </Select>
                  {errors.measuring_unit && (
                    <p
                      style={{
                        color: "#e50140",
                        fontSize: "0.8rem",
                        margin: 0,
                      }}
                    >
                      {errors.measuring_unit.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="dispatched_quantity"
              control={control}
              defaultValue=""
              rules={{
                required: "Quantity is required",
                min: {
                  value: 1,
                  message: `Minimum quantity to sell is 1`,
                },

                max: {
                  value: priceId[0] ? priceId[0].received_quantity : Infinity,
                  message:
                    priceId[0] && priceId[0].received_quantity > 0
                      ? `Maximum available quantity is ${priceId[0].received_quantity}`
                      : `No  enough product`,
                },
              }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Quantity <span style={{ color: "#e50140" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    type="number"
                    onChange={handleChange("dispatched_quantity")}
                    error={Boolean(errors.dispatched_quantity)}
                  />
                  {errors.dispatched_quantity && (
                    <p
                      style={{
                        color: "#e50140",
                        fontSize: "0.8rem",
                        margin: 0,
                      }}
                    >
                      {errors.dispatched_quantity.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="unit_price"
              control={control}
              defaultValue=""
              rules={{ required: "Unit price is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Unit price <span style={{ color: "#e50140" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    type="number"
                    onChange={handleChange("unit_price")}
                    error={Boolean(errors.unit_price)}
                  />
                  {errors.unit_price && (
                    <p
                      style={{
                        color: "#e50140",
                        fontSize: "0.8rem",
                        margin: 0,
                      }}
                    >
                      {errors.unit_price.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="total_price"
              control={control}
              defaultValue=""
              rules={{ required: "Total price is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Total price <span style={{ color: "#e50140" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    type="number"
                    error={Boolean(errors.total_price)}
                  />
                  {errors.total_price && (
                    <p
                      style={{
                        color: "#e50140",
                        fontSize: "0.8rem",
                        margin: 0,
                      }}
                    >
                      {errors.total_price.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="notes"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>Notes</InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("notes")}
                    error={Boolean(errors.notes)}
                  />
                  {errors.notes && (
                    <p
                      style={{
                        color: "#e50140",
                        fontSize: "0.8rem",
                        margin: 0,
                      }}
                    >
                      {errors.notes.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit(OnSubmit)}
            sx={{
              mt: 2,
              backgroundColor: " #1d3f6b",
              "&:hover": { backgroundColor: "#124c97" },
            }}
          >
            Submit
          </Button>
          {confirmation && <Notification message={confirmation.message} />}
          {error && <Notification message={error} color="#e50140" />}
        </FormGroup>
      </Paper>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#1d3f6b",
            borderRadius: 2,
            padding: 2,
          }}
        >
          <CircularProgress sx={{ color: "white" }} />
          <Typography variant="h6" sx={{ mt: 2, color: "white" }}>
            Submitting ...
          </Typography>
        </Box>
      </Backdrop>
    </Box>
  );
}
