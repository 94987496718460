import React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import EditNoteIcon from "@mui/icons-material/EditNote";

export default function MinStockLevel() {
  return (
    <>
      <Box
        sx={{
          width: "90%",
          backgroundColor: "white",
          margin: "0 auto",
          overflowX: "hidden",
          p: 5,
          mt: 3,
          gap: 3,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexFlow: "row nowrap",
            textAlign: "left",
            color: "#1d3f6b",
            gap: 7,
          }}
        >
          <Typography sx={{ whiteSpace: "nowrap" }}>Min Stock Level</Typography>
          <ButtonGroup>
            <Box
              sx={{
                width: 100,
                borderRadius: "3px 0px 0px 3px",
                pr: -3,
              }}
            >
              <Button
                disabled={
                  !JSON.parse(sessionStorage.getItem("permissions")).update_user
                }
                size="small"
                variant="text"
                sx={{
                  width: 100,
                  color: "#1d3f6b",
                  textTransform: "none",
                }}
              >
                <Tooltip title="Click to edit">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <EditNoteIcon />
                    <Typography>Edit</Typography>
                  </Box>
                </Tooltip>
              </Button>
            </Box>
          </ButtonGroup>
        </Box>
        <Typography>100</Typography>
      </Box>
    </>
  );
}
