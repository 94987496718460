import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import EvStationIcon from "@mui/icons-material/EvStation";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined";
import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import BikeScooterOutlinedIcon from "@mui/icons-material/BikeScooterOutlined";
import ElectricalServicesOutlinedIcon from "@mui/icons-material/ElectricalServicesOutlined";
import TimeToLeaveOutlinedIcon from "@mui/icons-material/TimeToLeaveOutlined";
import WarehouseOutlinedIcon from "@mui/icons-material/WarehouseOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import { Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import useFetch from "../../hook/useFetch";

const iconMapping = {
  "Fuel consumption": (
    <EvStationIcon sx={{ color: "#1d3f6b", fontSize: "7em" }} />
  ),
  "Labour direct": (
    <EngineeringOutlinedIcon sx={{ color: "#1d3f6b", fontSize: "7em" }} />
  ),
  Maintenance: (
    <HandymanOutlinedIcon sx={{ color: "#1d3f6b", fontSize: "7em" }} />
  ),
  Transport: (
    <PaymentsOutlinedIcon sx={{ color: "#1d3f6b", fontSize: "7em" }} />
  ),
  Utility: (
    <ElectricalServicesOutlinedIcon
      sx={{ color: "#1d3f6b", fontSize: "7em" }}
    />
  ),
  "Vehicle rent": (
    <TimeToLeaveOutlinedIcon sx={{ color: "#1d3f6b", fontSize: "7em" }} />
  ),
  "Warehouse rent": (
    <WarehouseOutlinedIcon sx={{ color: "#1d3f6b", fontSize: "7em" }} />
  ),
  Others: <PaymentsOutlinedIcon sx={{ color: "#1d3f6b", fontSize: "7em" }} />,
};

export default function ExpenseSheet({ dateStart, dateEnd }) {
  const [expenses, setExpenses] = useState([]);
  const [materials, setMaterials] = useState([]);

  const materialExpenseUrl = `https://wow.maflink.com/api/api/material-transactions?date_start=${encodeURIComponent(
    dateStart
  )}&date_end=${encodeURIComponent(dateEnd)}`;

  const expenseUrl = `https://wow.maflink.com/api/api/expenses?summary=true&date_start=${encodeURIComponent(
    dateStart
  )}&date_end=${encodeURIComponent(dateEnd)}`;

  const [expenseData] = useFetch(expenseUrl);
  const [materialData] = useFetch(materialExpenseUrl);

  useEffect(() => {
    expenseData && setExpenses(expenseData);
    materialData && setMaterials(materialData.data);
  }, [materialData, expenseData]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          gap: 3,
          pb: 5,
        }}
      >
        <Typography>Expenditures</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            gap: 3,
          }}
        >
          <Typography>
            <b>Grand</b>
          </Typography>
          <Typography>|</Typography>
          <Typography>
            Total cost :{" "}
            <b>
              {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(
                expenses.reduce(
                  (acc, expense) => acc + parseFloat(expense.total_cost || 0),
                  0
                ) +
                  materials.reduce(
                    (acc, material) =>
                      acc + parseFloat(material.total_cost || 0),
                    0
                  )
              )}
            </b>
          </Typography>
        </Box>
      </Box>
      {expenseData ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            overflowX: "scroll",
            gap: 3,
            pb: 5,
          }}
        >
          {expenses.length > 0 ? (
            <>
              <Paper
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  p: 5,
                  gap: 2,
                }}
              >
                <Typography sx={{ color: "#1d3f6b" }}>Row material</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 4,
                  }}
                >
                  <Inventory2OutlinedIcon
                    sx={{ color: "#1d3f6b", fontSize: "7em" }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: 3,
                    }}
                  >
                    <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
                      Total cost <i>( Rwf )</i>
                    </Typography>
                    <Typography variant="h4">
                      {new Intl.NumberFormat("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(
                        materials.reduce(
                          (acc, material) =>
                            acc + parseFloat(material.total_cost || 0),
                          0
                        )
                      )}
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 7 }}>
                      <Box sx={{ textAlign: "center", gap: 1 }}>
                        <Typography
                          sx={{ fontSize: "0.75em", whiteSpace: "nowrap" }}
                        >
                          VAT <i>( Rwf )</i>
                        </Typography>
                        <Typography
                          sx={{ fontSize: "1.0em", fontWeight: "bold" }}
                        >
                          {new Intl.NumberFormat("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(
                            materials.reduce(
                              (acc, material) =>
                                acc + parseFloat(material.vat || 0),
                              0
                            )
                          )}
                        </Typography>
                      </Box>
                      <Box sx={{ textAlign: "center", gap: 1 }}>
                        <Typography
                          sx={{ fontSize: "0.75em", whiteSpace: "nowrap" }}
                        >
                          With. Tax <i>( Rwf )</i>
                        </Typography>
                        <Typography
                          sx={{ fontSize: "1.0em", fontWeight: "bold" }}
                        >
                          {new Intl.NumberFormat("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(
                            materials.reduce(
                              (acc, material) =>
                                acc + parseFloat(material.with_tax || 0),
                              0
                            )
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Paper>
              {expenses.map(
                (expense, index) =>
                  expense.payment_category != "Others" && (
                    <Paper
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        p: 5,
                        gap: 2,
                      }}
                    >
                      <Typography sx={{ color: "#1d3f6b" }}>
                        {expense.payment_category}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 4,
                        }}
                      >
                        {iconMapping[expense.payment_category] || null}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 3,
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            Total cost <i>( Rwf )</i>
                          </Typography>
                          <Typography variant="h4">
                            {new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(expense.total_cost)}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 7,
                            }}
                          >
                            <Box sx={{ textAlign: "center", gap: 1 }}>
                              <Typography
                                sx={{
                                  fontSize: "0.75em",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                VAT <i>( Rwf )</i>
                              </Typography>
                              <Typography
                                sx={{ fontSize: "1.0em", fontWeight: "bold" }}
                              >
                                {new Intl.NumberFormat("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(expense.vat)}
                              </Typography>
                            </Box>
                            <Box sx={{ textAlign: "center", gap: 1 }}>
                              <Typography
                                sx={{
                                  fontSize: "0.75em",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                With. Tax <i>( Rwf )</i>
                              </Typography>
                              <Typography
                                sx={{ fontSize: "1.0em", fontWeight: "bold" }}
                              >
                                {new Intl.NumberFormat("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(expense.with_tax)}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Paper>
                  )
              )}
              {expenses.map(
                (expense, index) =>
                  expense.payment_category === "Others" && (
                    <Paper
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        p: 5,
                        gap: 2,
                      }}
                    >
                      <Typography sx={{ color: "#1d3f6b" }}>
                        {expense.payment_category}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 4,
                        }}
                      >
                        {iconMapping[expense.payment_category] || null}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 3,
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            Total cost <i>( Rwf )</i>
                          </Typography>
                          <Typography variant="h4">
                            {new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(expense.total_cost)}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 7,
                            }}
                          >
                            <Box sx={{ textAlign: "center", gap: 1 }}>
                              <Typography
                                sx={{
                                  fontSize: "0.75em",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                VAT <i>( Rwf )</i>
                              </Typography>
                              <Typography
                                sx={{ fontSize: "1.0em", fontWeight: "bold" }}
                              >
                                {new Intl.NumberFormat("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(expense.vat)}
                              </Typography>
                            </Box>
                            <Box sx={{ textAlign: "center", gap: 1 }}>
                              <Typography
                                sx={{
                                  fontSize: "0.75em",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                With. Tax <i>( Rwf )</i>
                              </Typography>
                              <Typography
                                sx={{ fontSize: "1.0em", fontWeight: "bold" }}
                              >
                                {new Intl.NumberFormat("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(expense.with_tax)}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Paper>
                  )
              )}
            </>
          ) : (
            <Typography sx={{ p: 5, color: "#888" }}>Not found</Typography>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            gap: 3,
          }}
        >
          <Skeleton
            variant="rectangular"
            width={300}
            height={200}
            animation="wave"
            sx={{ borderRadius: 1, p: 5 }}
          />
          <Skeleton
            variant="rectangular"
            width={300}
            height={200}
            animation="wave"
            sx={{ borderRadius: 1, p: 5 }}
          />
          <Skeleton
            variant="rectangular"
            width={300}
            height={200}
            animation="wave"
            sx={{ borderRadius: 1, p: 5 }}
          />
        </Box>
      )}
    </Box>
  );
}
