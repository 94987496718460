import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import AddIcon from "@mui/icons-material/Add";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useForm, Controller } from "react-hook-form";
import usePost from "../../hook/usePost";
import Notification from "../notification";

export default function AddMaterial() {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const [material, setMaterial] = useState({});

  const token = sessionStorage.getItem("token");
  const url = "https://wow.maflink.com/api/api/materials";

  const { loading, error, confirmation, post } = usePost(url);

  // Effect to calculate total_cost whenever quantity or unit_cost changes
  useEffect(() => {
    const total_cost = material.quantity * material.unit_cost;
    material.quantity &&
      material.unit_cost &&
      setValue("total_cost", total_cost);
  }, [material.quantity, material.unit_cost, setValue]);

  const OnSubmit = (material) => {
    material.mfg_date = dayjs(material.mfg_date).format("YYYY-MM-DD");
    material.expiry_date = dayjs(material.expiry_date).format("YYYY-MM-DD");

    material.data_encoder = sessionStorage.getItem("full_name");

    post(material, token);
  };

  const handleChange = (field) => (e) => {
    const value = e.target.value;
    setMaterial({ ...material, [field]: value });
    setValue(field, value);
  };

  const handleDateChange = (field) => (date) => {
    setMaterial({ ...material, [field]: date });
    setValue(field, date);
  };

  return (
    <Box sx={{ maxWidth: 1200 }}>
      <Paper
        elevation={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        <Stack
          sx={{
            width: "100%",
            backgroundColor: "whitesmoke",
            borderBottom: "2px solid #1d3f6b",
            p: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <AddIcon />
          <Typography color="inherit" variant="h6">
            Add material
          </Typography>
        </Stack>
        <FormGroup
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 5,
            padding: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 3,
              "& .MuiTextField-root": { m: 1, width: "21ch" },
            }}
          >
            <Controller
              name="material_name"
              control={control}
              defaultValue=""
              rules={{ required: "Material name is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Material name <span style={{ color: "#e50140" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("material_name")}
                    error={Boolean(errors.material_name)}
                  />
                  {errors.material_name && (
                    <p
                      style={{
                        color: "#e50140",
                        fontSize: "0.8rem",
                        margin: 0,
                      }}
                    >
                      {errors.material_name.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="material_id"
              control={control}
              defaultValue=""
              rules={{ required: "Material id is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Material id <span style={{ color: "#e50140" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("material_id")}
                    error={Boolean(errors.material_id)}
                  />
                  {errors.material_id && (
                    <p
                      style={{
                        color: "#e50140",
                        fontSize: "0.8rem",
                        margin: 0,
                      }}
                    >
                      {errors.material_id.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="category"
              control={control}
              defaultValue=""
              rules={{ required: "Category is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Category <span style={{ color: "#e50140" }}>*</span>
                  </InputLabel>
                  <Select
                    {...field}
                    onChange={handleChange("category")}
                    error={Boolean(errors.category)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {["Preform", "Cap", "Plastic", "Other"].map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.category && (
                    <p
                      style={{
                        color: "#e50140",
                        fontSize: "0.8rem",
                        margin: 0,
                      }}
                    >
                      {errors.category.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="description"
              control={control}
              defaultValue=""
              rules={{ required: "Description is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Description <span style={{ color: "#e50140" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("description")}
                    error={Boolean(errors.description)}
                  />
                  {errors.description && (
                    <p
                      style={{
                        color: "#e50140",
                        fontSize: "0.8rem",
                        margin: 0,
                      }}
                    >
                      {errors.description.message}
                    </p>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="quantity"
              control={control}
              defaultValue=""
              rules={{ required: "Quantity is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Quantity <span style={{ color: "#e50140" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    type="number"
                    onChange={handleChange("quantity")}
                    error={Boolean(errors.quantity)}
                  />
                  {errors.quantity && (
                    <p
                      style={{
                        color: "#e50140",
                        fontSize: "0.8rem",
                        margin: 0,
                      }}
                    >
                      {errors.quantity.message}
                    </p>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="measuring_unit"
              control={control}
              defaultValue=""
              rules={{ required: "Measuring unit is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Measuring unit <span style={{ color: "#e50140" }}>*</span>
                  </InputLabel>
                  <Select
                    {...field}
                    onChange={handleChange("measuring_unit")}
                    error={Boolean(errors.measuring_unit)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {["Number", "Pieces", "Liter", "Kg"].map(
                      (measuring_unit) => (
                        <MenuItem key={measuring_unit} value={measuring_unit}>
                          {measuring_unit}
                        </MenuItem>
                      )
                    )}
                  </Select>
                  {errors.measuring_unit && (
                    <p
                      style={{
                        color: "#e50140",
                        fontSize: "0.8rem",
                        margin: 0,
                      }}
                    >
                      {errors.measuring_unit.message}
                    </p>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="unit_cost"
              control={control}
              defaultValue=""
              rules={{ required: "Unit cost is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Unit cost <span style={{ color: "#e50140" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    type="number"
                    onChange={handleChange("unit_cost")}
                    error={Boolean(errors.unit_cost)}
                  />
                  {errors.unit_cost && (
                    <p
                      style={{
                        color: "#e50140",
                        fontSize: "0.8rem",
                        margin: 0,
                      }}
                    >
                      {errors.unit_cost.message}
                    </p>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="total_cost"
              control={control}
              defaultValue=""
              rules={{ required: "Total cost is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Total cost <span style={{ color: "#e50140" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    type="number"
                    error={Boolean(errors.total_cost)}
                  />
                  {errors.total_cost && (
                    <p
                      style={{
                        color: "#e50140",
                        fontSize: "0.8rem",
                        margin: 0,
                      }}
                    >
                      {errors.total_cost.message}
                    </p>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="supplier"
              control={control}
              defaultValue=""
              rules={{ required: "Supplier is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Supplier <span style={{ color: "#e50140" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("supplier")}
                    error={Boolean(errors.supplier)}
                  />
                  {errors.supplier && (
                    <p
                      style={{
                        color: "#e50140",
                        fontSize: "0.8rem",
                        margin: 0,
                      }}
                    >
                      {errors.supplier.message}
                    </p>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="supplier_contact"
              control={control}
              defaultValue=""
              rules={{ required: "Supplier contact is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Supplier contact <span style={{ color: "#e50140" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("supplier_contact")}
                    error={Boolean(errors.supplier_contact)}
                  />
                  {errors.supplier_contact && (
                    <p
                      style={{
                        color: "#e50140",
                        fontSize: "0.8rem",
                        margin: 0,
                      }}
                    >
                      {errors.supplier_contact.message}
                    </p>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="batch_number"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>Batch number</InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("batch_number")}
                    error={Boolean(errors.batch_number)}
                  />
                  {errors.batch_number && (
                    <p
                      style={{
                        color: "#e50140",
                        fontSize: "0.8rem",
                        margin: 0,
                      }}
                    >
                      {errors.batch_number.message}
                    </p>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="brand"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>Brand</InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("brand")}
                    error={Boolean(errors.brand)}
                  />
                  {errors.brand && (
                    <p
                      style={{
                        color: "#e50140",
                        fontSize: "0.8rem",
                        margin: 0,
                      }}
                    >
                      {errors.brand.message}
                    </p>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="notes"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>Notes</InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("notes")}
                    error={Boolean(errors.notes)}
                  />
                  {errors.notes && (
                    <p
                      style={{
                        color: "#e50140",
                        fontSize: "0.8rem",
                        margin: 0,
                      }}
                    >
                      {errors.notes.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name="mfg_date"
                control={control}
                defaultValue={null}
                rules={{ required: "Manufacturing date is required" }}
                render={({ field }) => (
                  <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                    <DatePicker
                      label={
                        <Typography>
                          Manuf. date{" "}
                          <span style={{ color: "#e50140" }}>*</span>
                        </Typography>
                      }
                      {...field}
                      onChange={handleDateChange("mfg_date")}
                      renderInput={(params) => (
                        <Input
                          type="date"
                          {...params.inputProps}
                          {...params.InputProps}
                        />
                      )}
                      error={Boolean(errors.mfg_date)}
                    />
                    {errors.mfg_date && (
                      <p
                        style={{
                          color: "#e50140",
                          fontSize: "0.8rem",
                          margin: 0,
                        }}
                      >
                        {errors.mfg_date.message}
                      </p>
                    )}
                  </FormControl>
                )}
              />
              <Controller
                name="expiry_date"
                control={control}
                defaultValue={null}
                rules={{ required: "Expriry date is required" }}
                render={({ field }) => (
                  <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                    <DatePicker
                      label={
                        <Typography>
                          Expiry date{" "}
                          <span style={{ color: "#e50140" }}>*</span>
                        </Typography>
                      }
                      {...field}
                      onChange={handleDateChange("expiry_date")}
                      renderInput={(params) => (
                        <Input
                          type="date"
                          {...params.inputProps}
                          {...params.InputProps}
                        />
                      )}
                      error={Boolean(errors.expiry_date)}
                    />
                    {errors.expiry_date && (
                      <p
                        style={{
                          color: "#e50140",
                          fontSize: "0.8rem",
                          margin: 0,
                        }}
                      >
                        {errors.expiry_date.message}
                      </p>
                    )}
                  </FormControl>
                )}
              />
            </LocalizationProvider>
          </Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit(OnSubmit)}
            sx={{
              mt: 2,
              backgroundColor: " #1d3f6b",
              "&:hover": { backgroundColor: "#124c97" },
            }}
          >
            Submit
          </Button>
          {confirmation && <Notification message={confirmation.message} />}
          {error && <Notification message={error} color="#e50140" />}
        </FormGroup>
      </Paper>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#1d3f6b",
            borderRadius: 2,
            padding: 2,
          }}
        >
          <CircularProgress sx={{ color: "white" }} />
          <Typography variant="h6" sx={{ mt: 2, color: "white" }}>
            Submitting ...
          </Typography>
        </Box>
      </Backdrop>
    </Box>
  );
}
