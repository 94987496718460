import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Menu from "@mui/material/Menu";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Button, Stack } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";

import useFetch from "../hook/useFetch";
import ExportToPdf from "./exportToPdf";

//Dropdown view component
function Dropdown() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        textTransform: "none",
        color: "black",
        ml: -1,
      }}
    >
      <AssessmentOutlinedIcon />
      <Typography sx={{ color: "black" }}>Summarised Report</Typography>
    </Box>
  );
}

function DateFilter({ handleDateFilter }) {
  const [fromValue, setFromValue] = React.useState(null);
  const [toValue, setToValue] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleDateFilter(fromValue, toValue);
  }, [fromValue, toValue]);

  return (
    <div>
      <Button
        variant="text"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          textTransform: "none",
          color: "#1d3f6b",
        }}
      >
        <FilterListOutlinedIcon fontSize="small" />
        <Typography>Date filter</Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box sx={{ m: 4 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker", "DatePicker"]}>
              <DatePicker
                label="From"
                value={fromValue}
                onChange={(newValue) => setFromValue(newValue)}
              />
              <DatePicker
                label="To"
                value={toValue}
                onChange={(newValue) => setToValue(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Box>
      </Menu>
    </div>
  );
}

function ReportBody({
  date_start,
  date_end,
  products = [],
  sortedMaterialRows = [],
  sales = [],
  preorders = [],
  expenses = [],
}) {
  return (
    <TableContainer sx={{ mt: 3 }}>
      <Box
        sx={{
          width: "90%",
          display: "flex",
          flexFlow: "row wrap",
          justifyContent: "space-between",
          alignItems: "center",
          pl: 12,
          gap: 3,
        }}
      >
        <img
          style={{
            width: 120,
            height: "auto",
          }}
          src="https://wow.maflink.com/logo.png"
        />
        <Typography variant="h5" sx={{ textDecoration: "underline" }}>
          Report
        </Typography>
        <Typography>
          {date_start &&
            date_end &&
            `Date: ${dayjs(date_start).format("YYYY-MM-DD")} -- ${dayjs(
              date_end
            ).format("YYYY-MM-DD")}`}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          p: 4,
          m: "16px 0px 16px 0px",
        }}
      >
        <Divider textAlign="left">
          <Chip
            label="Product Stock"
            size="small"
            sx={{ fontWeight: "bold" }}
          />
        </Divider>
        {products.length > 0 ? (
          <Table sx={{ m: 3 }}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Item
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Quantity
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Unit cost
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Total cost
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: "none" },
                  }}
                >
                  <TableCell sx={{ borderBottom: "none" }}>
                    {product.product_name}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {new Intl.NumberFormat("en-US").format(
                      product.received_quantity
                    )}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(product.unit_cost)}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(product.total_cost)}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    borderTop: "1px solid",
                    textWrap: "nowrap",
                  }}
                >
                  Grand Total (Rwf)
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderTop: "1px solid" }}
                >
                  {new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(
                    products.reduce(
                      (acc, product) => acc + parseFloat(product.total_cost),
                      0
                    )
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : (
          <Typography sx={{ p: 3 }}>No products</Typography>
        )}
      </Box>

      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          p: 4,
          m: "16px 0px 16px 0px",
        }}
      >
        <Divider textAlign="left">
          <Chip
            label="Material Stock"
            size="small"
            sx={{ fontWeight: "bold" }}
          />
        </Divider>
        {sortedMaterialRows.length > 0 ? (
          <Table sx={{ m: 3 }}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Item
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Quantity
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Unit cost
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Total cost
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row nowrap",
                  alignItems: "center",
                  gap: 3,
                }}
              >
                <Typography variant="h6">Preform</Typography>
                <Typography>-</Typography>
                <Typography variant="body2">
                  {new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(
                    sortedMaterialRows
                      .filter((row) => row.category === "Preform")
                      .reduce(
                        (acc, material) =>
                          acc + parseFloat(material.total_cost),
                        0
                      )
                  )}{" "}
                  Rwf
                </Typography>
              </Box>
              {sortedMaterialRows
                .filter((row) => row.category === "Preform")
                .map((material, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: "none" },
                    }}
                  >
                    <TableCell sx={{ borderBottom: "none" }}>
                      {material.material_name}
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: "none" }}>
                      {new Intl.NumberFormat("en-US").format(material.quantity)}
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: "none" }}>
                      {new Intl.NumberFormat("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(material.unit_cost)}
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: "none" }}>
                      {new Intl.NumberFormat("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(material.total_cost)}
                    </TableCell>
                  </TableRow>
                ))}
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row nowrap",
                  alignItems: "center",
                  gap: 5,
                }}
              >
                <Typography variant="h6">Others</Typography>
                <Typography>-</Typography>
                <Typography variant="body2">
                  {new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(
                    sortedMaterialRows
                      .filter((row) => row.category != "Preform")
                      .reduce(
                        (acc, material) =>
                          acc + parseFloat(material.total_cost),
                        0
                      )
                  )}{" "}
                  Rwf
                </Typography>
              </Box>
              {sortedMaterialRows
                .filter((row) => row.category != "Preform")
                .map((material, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: "none" },
                    }}
                  >
                    <TableCell sx={{ borderBottom: "none" }}>
                      {material.material_name}
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: "none" }}>
                      {new Intl.NumberFormat("en-US").format(material.quantity)}
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: "none" }}>
                      {new Intl.NumberFormat("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(material.unit_cost)}
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: "none" }}>
                      {new Intl.NumberFormat("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(material.total_cost)}
                    </TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    borderTop: "1px solid",
                    textWrap: "nowrap",
                  }}
                >
                  Grand Total (Rwf)
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderTop: "1px solid" }}
                >
                  {new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(
                    sortedMaterialRows.reduce(
                      (acc, material) => acc + parseFloat(material.total_cost),
                      0
                    )
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : (
          <Typography sx={{ p: 3 }}>No materials</Typography>
        )}
      </Box>

      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          p: 4,
          m: "16px 0px 16px 0px",
        }}
      >
        <Divider textAlign="left">
          <Chip label="Sales" size="small" sx={{ fontWeight: "bold" }} />
        </Divider>
        {sales.length > 0 ? (
          <Table sx={{ m: 3 }}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Item
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Quantity
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Average Unit price
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Total price
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  VAT
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Withholding tax
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sales.map((sale, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: "none" },
                  }}
                >
                  <TableCell sx={{ borderBottom: "none" }}>
                    {sale.product_name}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {sale.quantity}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(sale.unit_price)}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(sale.total_price)}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(sale.tax)}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(sale.withholding_tax)}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    borderTop: "1px solid",
                    textWrap: "nowrap",
                  }}
                >
                  Grand Total (Rwf)
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderTop: "1px solid" }}
                >
                  {new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(
                    sales.reduce(
                      (acc, sale) => acc + parseFloat(sale.total_price),
                      0
                    )
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderTop: "1px solid" }}
                >
                  {new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(
                    sales.reduce((acc, sale) => acc + parseFloat(sale.tax), 0)
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderTop: "1px solid" }}
                >
                  {new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(
                    sales.reduce(
                      (acc, sale) => acc + parseFloat(sale.withholding_tax),
                      0
                    )
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : (
          <Typography sx={{ p: 3 }}>No sales</Typography>
        )}
      </Box>

      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          p: 4,
          m: "16px 0px 16px 0px",
        }}
      >
        <Divider textAlign="left">
          <Chip label="Pre-Order" size="small" sx={{ fontWeight: "bold" }} />
        </Divider>
        {preorders.length > 0 ? (
          <Table sx={{ m: 3 }}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Item
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Quantity
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Average Unit price
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Total price
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  VAT
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Withholding tax
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {preorders.map((preorder, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: "none" },
                  }}
                >
                  <TableCell sx={{ borderBottom: "none" }}>
                    {preorder.product_name}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {preorder.quantity}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(preorder.unit_price)}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(preorder.total_price)}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(preorder.tax)}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(preorder.withholding_tax)}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    borderTop: "1px solid",
                    textWrap: "nowrap",
                  }}
                >
                  Grand Total (Rwf)
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderTop: "1px solid" }}
                >
                  {new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(
                    preorders.reduce(
                      (acc, preorder) => acc + parseFloat(preorder.total_price),
                      0
                    )
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderTop: "1px solid" }}
                >
                  {new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(
                    preorders.reduce(
                      (acc, preorder) =>
                        acc + (preorder.tax ? parseFloat(preorder.tax) : 0),
                      0
                    )
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderTop: "1px solid" }}
                >
                  {new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(
                    preorders.reduce(
                      (acc, preorder) =>
                        acc +
                        (preorder.withholding_tax
                          ? parseFloat(preorder.withholding_tax)
                          : 0),
                      0
                    )
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : (
          <Typography sx={{ p: 3 }}>No preorder</Typography>
        )}
      </Box>

      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          p: 4,
          m: "16px 0px 16px 0px",
        }}
      >
        <Divider textAlign="left">
          <Chip label="Expenses" size="small" sx={{ fontWeight: "bold" }} />
        </Divider>
        {products.length > 0 ? (
          <Table sx={{ m: 3 }}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Payment category
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Measuring unit
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Quantity
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Unit cost
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Total cost
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  VAT
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Withholding tax
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {expenses.map((expense, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: "none" },
                  }}
                >
                  <TableCell sx={{ borderBottom: "none" }}>
                    {expense.payment_category}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {expense.measuring_unit}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {new Intl.NumberFormat("en-US").format(expense.quantity)}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(expense.unit_cost)}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(expense.total_cost)}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(expense.vat)}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(expense.with_tax)}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    borderTop: "1px solid",
                    textWrap: "nowrap",
                  }}
                >
                  Grand Total (Rwf)
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderTop: "1px solid" }}
                >
                  {new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(
                    expenses.reduce(
                      (acc, expense) => acc + parseFloat(expense.total_cost),
                      0
                    )
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderTop: "1px solid" }}
                >
                  {new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(
                    expenses.reduce(
                      (acc, expense) => acc + parseFloat(expense.vat),
                      0
                    )
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderTop: "1px solid" }}
                >
                  {new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(
                    expenses.reduce(
                      (acc, expense) => acc + parseFloat(expense.with_tax),
                      0
                    )
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : (
          <Typography sx={{ p: 3 }}>No expense</Typography>
        )}
      </Box>
    </TableContainer>
  );
}
//Component for table toolbar
function EnhancedTableToolbar({ handleDateFilter, Comp }) {
  // Common styles
  const toolbarStyles = {
    display: "flex",
    flexFlow: "row wrap",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
    borderBottom: "2px solid #1d3f6b",
    position: "sticky",
    gap: 2,
    left: 0,
    p: 2,
  };

  return (
    <Stack sx={toolbarStyles}>
      <Dropdown />
      <DateFilter handleDateFilter={handleDateFilter} />
      <ExportToPdf Comp={Comp} />
    </Stack>
  );
}

//Collective component to be exported
export default function SummarisedReport() {
  const [products, setProducts] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [sales, setSales] = useState([]);
  const [preorders, setPreorders] = useState([]);
  const [expenses, setExpenses] = useState([]);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [preorder, setPreorder] = useState("preorder");

  // Initialize dates as null (no default values)
  const [date_start, setDateStart] = useState(null);
  const [date_end, setDateEnd] = useState(null);

  const token = sessionStorage.getItem("token");

  const productUrl = `https://wow.maflink.com/api/api/products?date_start=${encodeURIComponent(
    date_start
  )}&date_end=${encodeURIComponent(date_end)}`;

  const materialUrl = `https://wow.maflink.com/api/api/materials?date_start=${encodeURIComponent(
    date_start
  )}&date_end=${encodeURIComponent(date_end)}`;

  const salesUrl = `https://wow.maflink.com/api/api/sales-transactions?summary=summary&date_start=${encodeURIComponent(
    date_start
  )}&date_end=${encodeURIComponent(date_end)}`;

  const preordersUrl = `https://wow.maflink.com/api/api/sales-transactions?page=${page}&preorder=${preorder}&search=${encodeURIComponent(
    search
  )}&date_start=${encodeURIComponent(date_start)}&date_end=${encodeURIComponent(
    date_end
  )}`;

  const expenseUrl = `https://wow.maflink.com/api/api/expenses?page=${page}&summary=true&date_start=${encodeURIComponent(
    date_start
  )}&date_end=${encodeURIComponent(date_end)}`;

  const [productData] = useFetch(productUrl);
  const [materialData] = useFetch(materialUrl);
  const [salesData] = useFetch(salesUrl);
  const [preordersData] = useFetch(preordersUrl);
  const [expenseData] = useFetch(expenseUrl);

  useEffect(() => {
    productData && setProducts(productData);
    materialData && setMaterials(materialData);
    salesData && setSales(salesData);
    preordersData && setPreorders(preordersData.data);
    expenseData && setExpenses(expenseData);
  }, [productData, salesData, preordersData, materialData, expenseData]);

  // Sort rows only when they are updated
  const sortedMaterialRows = materials.sort((a, b) => {
    // Function to convert "1/2" to 0.5, "1" to 1, etc.
    const getNumericalValue = (materialName) => {
      const numberPart = materialName.split(" ")[0]; // Extract the first part (before the space)
      if (numberPart.includes("/")) {
        const [numerator, denominator] = numberPart.split("/").map(Number);
        return numerator / denominator;
      }
      return parseFloat(numberPart);
    };

    // Compare based on the numerical values
    return (
      getNumericalValue(a.material_name) - getNumericalValue(b.material_name)
    );
  });

  // Function to handle date range filter
  const handleDateFilter = (startDate, endDate) => {
    // Format the dates if they are provided, otherwise keep them as null
    setDateStart(startDate ? dayjs(startDate).format("YYYY-MM-DD") : null);
    setDateEnd(endDate ? dayjs(endDate).format("YYYY-MM-DD") : null);
  };

  console.log(materials);

  return (
    <Paper sx={{ width: "min(1000px,100%)", backgroundColor: "#FBFBFB" }}>
      <EnhancedTableToolbar
        handleDateFilter={handleDateFilter}
        Comp={(props) => (
          <ReportBody
            {...props}
            date_start={date_start}
            date_end={date_end}
            products={products}
            sortedMaterialRows={sortedMaterialRows}
            sales={sales}
            preorders={preorders}
            expenses={expenses}
          />
        )}
      />
      <ReportBody
        date_start={date_start}
        date_end={date_end}
        products={products}
        sortedMaterialRows={sortedMaterialRows}
        sales={sales}
        preorders={preorders}
        expenses={expenses}
      />
    </Paper>
  );
}
