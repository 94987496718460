import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import AppsIcon from "@mui/icons-material/Apps";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import useFetch from "../../hook/useFetch";
import LoadingSkeleton from "../loadingSkeleton";

export default function ProductStock({ dateStart, dateEnd }) {
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");

  const fetchUrl = `https://wow.maflink.com/api/api/products?date_start=${encodeURIComponent(
    dateStart
  )}&date_end=${encodeURIComponent(dateEnd)}`;

  const [data, fetchData] = useFetch(fetchUrl);

  useEffect(() => {
    data && setRows(data);
  }, [data]);

  if (rows.length > 0) {
    rows.sort((a, b) => {
      // Function to convert "1/2" to 0.5, "1" to 1, etc.
      const getNumericalValue = (productName) => {
        const numberPart = productName.split(" ")[0]; // Extract the first part (before the space)
        if (numberPart.includes("/")) {
          const [numerator, denominator] = numberPart.split("/").map(Number);
          return numerator / denominator;
        }
        return parseFloat(numberPart);
      };

      // Compare based on the numerical values
      return (
        getNumericalValue(a.product_name) - getNumericalValue(b.product_name)
      );
    });
  }

  return (
    <TableContainer
      component={Paper}
      sx={{
        width: "20%",
        margin: "0 auto",
        overflowX: "hidden",
        boxShadow: 3,
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderBottom: "2px solid #1d3f6b",
          // backgroundColor: "#1d3f6b",
          borderRadius: "5px 5px 0 0",
          m: -3,
          mb: 2,
          gap: 3,
          p: 2,
        }}
      >
        <AppsIcon sx={{ color: "#1d3f6b" }} />
        <Typography variant="h6" sx={{ color: "#1d3f6b" }}>
          Product Stock
        </Typography>
      </Box>
      {data ? (
        rows.length > 0 ? (
          <Table>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ fontWeight: "bold", color: "#1d3f6b" }}>
                    {row.product_name}
                  </TableCell>
                  <TableCell align="right" sx={{ color: "#555" }}>
                    {row.received_quantity}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography sx={{ p: 5, color: "#888" }}>Not found</Typography>
        )
      ) : (
        <LoadingSkeleton />
      )}
    </TableContainer>
  );
}
