import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import AppsIcon from "@mui/icons-material/Apps";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import useFetch from "../../hook/useFetch";
import LoadingSkeleton from "../loadingSkeleton";

export default function MaterialStock({ dateStart, dateEnd }) {
  const [rows, setRows] = useState([]); // Initial empty state for rows

  const fetchUrl = `https://wow.maflink.com/api/api/materials?date_start=${encodeURIComponent(
    dateStart
  )}&date_end=${encodeURIComponent(dateEnd)}`;

  const [data] = useFetch(fetchUrl);

  // Handling data fetch and update the rows state
  useEffect(() => {
    if (data) {
      setRows(data); // Set rows when data is available
    }
  }, [data]);

  // Sort rows only when they are updated
  const sortedRows = rows.sort((a, b) => {
    // Function to convert "1/2" to 0.5, "1" to 1, etc.
    const getNumericalValue = (materialName) => {
      const numberPart = materialName.split(" ")[0]; // Extract the first part (before the space)
      if (numberPart.includes("/")) {
        const [numerator, denominator] = numberPart.split("/").map(Number);
        return numerator / denominator;
      }
      return parseFloat(numberPart);
    };

    // Compare based on the numerical values
    return (
      getNumericalValue(a.material_name) - getNumericalValue(b.material_name)
    );
  });

  return (
    <TableContainer
      component={Paper}
      sx={{
        width: "20%",
        margin: "0 auto",
        overflowX: "hidden",
        boxShadow: 3,
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderBottom: "2px solid #1d3f6b",
          // backgroundColor: "#1d3f6b",
          borderRadius: "5px 5px 0 0",
          m: -3,
          mb: 2,
          gap: 3,
          p: 2,
        }}
      >
        <AppsIcon sx={{ color: "#1d3f6b" }} />
        <Typography variant="h6" sx={{ color: "#1d3f6b" }}>
          Material Stock
        </Typography>
      </Box>
      {data ? (
        rows.length > 0 ? (
          <Table>
            <TableBody>
              <Typography
                sx={{
                  fontSize: "1.15em",
                  fontWeight: "bold",
                  color: "#1d3f6b",
                  pl: 2,
                }}
              >
                Preform
              </Typography>
              {sortedRows
                .filter((row) => row.category === "Preform")
                .map((material) => (
                  <TableRow key={material.id}>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        color: "#1d3f6b",
                      }}
                    >
                      {material.material_name}
                    </TableCell>
                    <TableCell align="right" sx={{ color: "#555" }}>
                      {material.quantity}
                    </TableCell>
                  </TableRow>
                ))}
              <TableRow
                sx={{
                  borderBottom: "1px solid #1d3f6b",
                }}
              />
              {sortedRows
                .filter((row) => row.category !== "Preform")
                .map((material) => (
                  <TableRow key={material.id}>
                    <TableCell
                      sx={{
                        fontSize: "1.15em",
                        fontWeight: "bold",
                        color: "#1d3f6b",
                      }}
                    >
                      {material.material_name}
                    </TableCell>
                    <TableCell align="right" sx={{ color: "#555" }}>
                      {material.quantity}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        ) : (
          <Typography sx={{ p: 5, color: "#888" }}>Not found</Typography>
        )
      ) : (
        <LoadingSkeleton />
      )}
    </TableContainer>
  );
}
