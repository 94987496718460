import React, { useState, useEffect } from "react";
import { Paper, Box, Typography, Divider } from "@mui/material";
import AppsIcon from "@mui/icons-material/Apps";
import Skeleton from "@mui/material/Skeleton";
import useFetch from "../../hook/useFetch";

export default function TotalSales({ dateStart, dateEnd }) {
  const [rows, setRows] = useState([]);
  const [summary, setSummary] = useState(true);

  const fetchUrl = `https://wow.maflink.com/api/api/sales-transactions?summary=${summary}&date_start=${encodeURIComponent(
    dateStart
  )}&date_end=${encodeURIComponent(dateEnd)}`;

  const [data, fetchData] = useFetch(fetchUrl);

  useEffect(() => {
    if (data) setRows(data);
  }, [data]);

  rows.sort((a, b) => {
    const getNumericalValue = (productName) => {
      const numberPart = productName.split(" ")[0];
      if (numberPart.includes("/")) {
        const [numerator, denominator] = numberPart.split("/").map(Number);
        return numerator / denominator;
      }
      return parseFloat(numberPart);
    };

    return (
      getNumericalValue(a.product_name) - getNumericalValue(b.product_name)
    );
  });

  console.log(rows);

  return (
    <Paper
      sx={{
        width: { lg: "70%", md: "70%", sm: "100%", xs: "100%" },
        p: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderBottom: "2px solid #1d3f6b",
          // backgroundColor: "#1d3f6b",
          borderRadius: "5px 5px 0 0",
          m: -1,
          gap: 3,
          p: 1.5,
        }}
      >
        <AppsIcon sx={{ color: "#1d3f6b" }} />
        <Typography variant="h6" sx={{ color: "#1d3f6b" }}>
          Total Sales
        </Typography>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ mx: 1, borderColor: "#ddd" }}
        />
        <Typography sx={{ color: "#1d3f6b" }}>
          {new Intl.NumberFormat().format(
            rows.reduce((sum, row) => sum + Number(row.total_price), 0)
          )}{" "}
          Rwf
        </Typography>
      </Box>
      {data ? (
        rows.length > 0 ? (
          <Box sx={{ display: "flex", overflowX: "auto", gap: 3, p: 3 }}>
            {rows.map((row, index) => (
              <Paper
                key={index}
                sx={{
                  minWidth: 350,
                  display: "flex",
                  alignItems: "center",
                  gap: 3,
                  p: 5,
                  backgroundColor: "#ffffff",
                  borderRadius: "20px",
                  boxShadow:
                    "inset 0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.05)",
                }}
              >
                <Box>
                  <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                    {row.product_name}
                  </Typography>
                </Box>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ mx: 1, borderColor: "#ddd" }}
                />
                <Box>
                  <Typography sx={{ color: "#333" }}>
                    {new Intl.NumberFormat().format(Number(row.quantity))} Packs
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{ color: "#777", whiteSpace: "nowrap" }}
                  >
                    {new Intl.NumberFormat().format(Number(row.total_price))}{" "}
                    Rwf
                  </Typography>
                </Box>
              </Paper>
            ))}
          </Box>
        ) : (
          <Typography sx={{ p: 5, color: "#888" }}>Not found</Typography>
        )
      ) : (
        <>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 5 }}>
            <Skeleton width={400} height={180} />
            <Skeleton width={400} height={180} />
            <Skeleton width={400} height={180} />
          </Box>
          <Skeleton width="100%" height={40} />
        </>
      )}
    </Paper>
  );
}
