import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Paper,
  Tooltip,
  Button,
} from "@mui/material";
import AppsIcon from "@mui/icons-material/Apps";
import ButtonGroup from "@mui/material/ButtonGroup";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import EditNoteIcon from "@mui/icons-material/EditNote";
import useFetch from "../../hook/useFetch";
import LoadingSkeleton from "../loadingSkeleton";
import useUpdate from "../../hook/useUpdate";

export default function PriceSetting() {
  const [rows, setRows] = useState([]);
  const [product, setProduct] = useState({
    product_id: "003",
    unit_price: 210,
  });
  const [edit, setEdit] = useState(0);
  const [id, setId] = useState("");
  const cellRef = useRef(null); // Reference for the editable cell

  const fetchUrl = `https://wow.maflink.com/api/api/products?date_start=null&date_end=null`;
  const [data, fetchData] = useFetch(fetchUrl);

  useEffect(() => {
    data && setRows(data);
  }, [data]);

  const url = "https://wow.maflink.com/api/api/products/" + id;
  const { loading, error, confirmation, update } = useUpdate(url);
  const permit = JSON.parse(sessionStorage.getItem("permissions"));

  useEffect(() => {
    if (cellRef.current && edit) {
      cellRef.current.focus(); // Focus the cell if it's in edit mode
    }
  }, [edit]);

  if (rows.length > 0) {
    rows.sort((a, b) => {
      // Function to convert "1/2" to 0.5, "1" to 1, etc.
      const getNumericalValue = (productName) => {
        const numberPart = productName.split(" ")[0]; // Extract the first part (before the space)
        if (numberPart.includes("/")) {
          const [numerator, denominator] = numberPart.split("/").map(Number);
          return numerator / denominator;
        }
        return parseFloat(numberPart);
      };

      // Compare based on the numerical values
      return (
        getNumericalValue(a.product_name) - getNumericalValue(b.product_name)
      );
    });
  }

  function handleEdit(rowId) {
    setId(rowId);
    setEdit(rowId);
  }

  function handleUpdate(product_id) {
    // Use a functional update to ensure the previous state is used
    setProduct((prevProduct) => {
      const updatedProduct = {
        ...prevProduct,
        product_id: product_id,
      };

      // Call update after the Product state is updated
      update(updatedProduct, "");
    });

    setEdit(0);
    fetchData(); // Fetch data after update
  }

  console.log(confirmation);

  return (
    <TableContainer
      component={Paper}
      sx={{
        width: "90%",
        margin: "0 auto",
        overflowX: "hidden",
        boxShadow: 3,
        p: 2,
        mt: 5,
        mb: 7,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderBottom: "2px solid #1d3f6b",
          borderRadius: "5px 5px 0 0",
          gap: 12,
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            p: 2,
          }}
        >
          <AppsIcon sx={{ color: "#1d3f6b" }} />
          <Typography variant="h6" sx={{ color: "#1d3f6b" }}>
            Price List
          </Typography>
        </Box>
        {loading && <CircularProgress color="inherit" size="30px" />}
      </Box>
      {data ? (
        rows.length > 0 ? (
          <Table>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ fontWeight: "bold", color: "#1d3f6b" }}>
                    {row.product_name}
                  </TableCell>
                  <TableCell
                    contentEditable={row.id === edit}
                    ref={row.id === edit ? cellRef : null}
                    suppressContentEditableWarning={true}
                    align="right"
                    sx={{
                      color: "#555",
                      cursor: row.id === edit ? "text" : "default",
                    }}
                    onBlur={(e) =>
                      setProduct({ ...product, unit_price: e.target.innerText })
                    }
                  >
                    {row.unit_price}
                  </TableCell>
                  <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                    <ButtonGroup>
                      <Box
                        sx={{
                          width: 100,
                          backgroundColor:
                            row.id !== edit ? "#1d3f6b" : "#124c97",
                          borderRadius: "3px 0px 0px 3px",
                        }}
                      >
                        {row.id !== edit ? (
                          <Button
                            disabled={loading}
                            size="small"
                            variant="text"
                            sx={{
                              width: 100,
                              color: "white",
                              textTransform: "none",
                            }}
                            onClick={() => handleEdit(row.id)}
                          >
                            <Tooltip title="Click to edit">
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: 1,
                                }}
                              >
                                <EditNoteIcon />
                                <Typography> Edit</Typography>
                              </Box>
                            </Tooltip>
                          </Button>
                        ) : (
                          <Button
                            size="small"
                            variant="text"
                            sx={{
                              width: 100,
                              color: "white",
                              textTransform: "none",
                            }}
                            onClick={() => handleUpdate(row.product_id)}
                          >
                            <Tooltip title="Click to save">
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: 1,
                                }}
                              >
                                <SaveOutlinedIcon />
                                <Typography> Save</Typography>
                              </Box>
                            </Tooltip>
                          </Button>
                        )}
                      </Box>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography sx={{ p: 5, color: "#888" }}>No product found</Typography>
        )
      ) : (
        <LoadingSkeleton />
      )}
    </TableContainer>
  );
}
